<template>
  <div>
    <el-form
      ref="form"
      inline
      size="small"
      label-position="right"
      label-width="210px"
      :model="form"
      :rules="rules"
    >
      <p class="header">{{ $t('do_payment_title1') }}</p>
      <div class="content">
        <el-form-item :label="$t('do_payment_title2')" prop="accountId">
          <el-select
            v-model="form.accountId"
            :placeholder="$t('COMMON_ERR4')"
            @change="handleWithdraw"
            style="width: 300px"
          >
            <el-option
              v-for="acc in account_list"
              :key="acc.id"
              :value="acc.id"
              :label="acc.accountName"
            ></el-option>
          </el-select>
        </el-form-item>
        <div class="acc-info-box" v-if="form.accountId">
          <el-form-item :label="`${$t('withdraw_title3')}:`">
            <el-input
              disabled
              :value="current_account && current_account.accountName"
              style="width: 300px"
            />
          </el-form-item>
          <el-form-item :label="`${$t('withdraw_title4')}:`">
            <el-input
              disabled
              :value="current_account && current_account.accountNo"
              style="width: 300px"
            />
          </el-form-item>
          <el-form-item :label="`${$t('withdraw_title5')}:`">
            <el-input
              disabled
              :value="current_account && current_account.address"
              style="width: 300px"
            />
          </el-form-item>
          <el-form-item label="SWIFT:">
            <el-input
              disabled
              :value="current_account && current_account.swiftCode"
              style="width: 300px"
            />
          </el-form-item>
          <el-form-item :label="`${$t('home_page_title29')}:`">
            <el-input
              disabled
              :value="current_account && current_account.accountBank"
              style="width: 300px"
            />
          </el-form-item>
          <el-form-item :label="`${$t('home_page_title30')}:`">
            <el-input
              disabled
              :value="current_account && current_account.bankAddress"
              style="width: 300px"
            />
          </el-form-item>
        </div>
      </div>
      <p class="header">{{ $t('withdraw_title19') }}</p>
      <div class="content flex-content">
        <el-form-item :label="$t('withdraw_title20')" prop="currency">
          <el-select
            :placeholder="$t('COMMON_ERR4')"
            v-model="form.currency"
            @change="v => handleCurrencySelect(v, '1')"
            style="width: 300px"
          >
            <el-option
              v-for="c in currency_list"
              :label="c.currency"
              :key="c.currency"
              :value="c.currency"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('withdraw_title8')">
          <el-input disabled :value="balance1" style="width: 300px" />
        </el-form-item>
        <el-form-item :label="$t('withdraw_title21')" prop="amount">
          <el-input
            :placeholder="$t('COMMON_ERR4')"
            v-model="form.amount"
            onkeyup="value=value.match(/\d+\.?\d{0,20}/);this.dispatchEvent(new Event('input'))"
            @input="debounce_input_change"
            style="width: 300px"
          />
        </el-form-item>
        <el-form-item :label="$t('withdraw_title10')" prop="feeType">
          <a-radio-group
            :options="options"
            v-model="form.feeType"
            @input="() => getFee()"
          />
        </el-form-item>
        <el-form-item :label="$t('withdraw_title11')">
          <el-select
            :placeholder="$t('COMMON_ERR4')"
            v-model="form.feeCurrency"
            @change="v => handleCurrencySelect(v, '2')"
            disabled
            style="width: 300px"
          >
            <el-option
              v-for="c in currency_list"
              :label="c.currency"
              :key="c.currency"
              :value="c.currency"
            ></el-option>
          </el-select>
          <!-- <el-select
            :placeholder="$t('COMMON_ERR4')"
            v-model="form.feeCurrency"
            @change="v => handleCurrencySelect(v, '2')"
            :disabled="form.feeType === 'BEN'"
          >
            <el-option
              v-for="c in currency_list"
              :label="c.currency"
              :key="c.currency"
              :value="c.currency"
            ></el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item :label="$t('withdraw_title12')">
          <el-input
            :value="form.feeType === 'BEN' ? '0' : balance2"
            disabled
            style="width: 300px"
          />
        </el-form-item>
        <el-form-item :label="$t('withdraw_title13')">
          <el-input
            :value="form.feeType === 'BEN' ? '0' : service_charge"
            disabled
            style="width: 300px"
          />
        </el-form-item>
        <!-- <el-form-item label="预计到账金额">
          <el-input :value="receivedAmount" disabled style="width: 300px" />
        </el-form-item> -->
        <el-form-item :label="$t('withdraw_title14')" prop="usageType">
          <el-select
            :placeholder="$t('COMMON_ERR4')"
            v-model="form.usageType"
            style="width: 300px"
          >
            <el-option
              value="一般商业用途"
              :label="$t('withdraw_title15')"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('withdraw_title16')" prop="remark">
          <el-input v-model="form.remark" style="width: 300px" />
        </el-form-item>
        <p class="header">{{ $t('withdraw_title17') }}</p>
        <div class="content">
          <p>
            <span style="color: red">*</span>
            <span style="color: #606266">{{ $t('withdraw_title18') }}</span>
          </p>
          <el-radio v-model="payer" :label="hainaName">
            {{ hainaName }}
          </el-radio>
          <el-radio v-model="payer" :label="vaName">{{ vaName }}</el-radio>
        </div>
      </div>
      <p class="header">{{ $t('do_payment_title3') }}</p>
      <div class="content">
        <p>
          <span style="color: red">*</span>
          <span style="color: #606266">
            {{ $t('do_payment_title4') }}
          </span>
        </p>
        <el-form-item label="" prop="fileUrl">
          <el-upload
            :action="this.fileAction"
            :headers="uploadHeader"
            :limit="1"
            :on-success="response => handleSuccess(response)"
            :file-list="fileList"
            :on-exceed="handleExceed"
          >
            <i class="el-icon-upload" style="color: #1890ff"></i>
            <a>{{ $t('incoming_company_upload1') }}</a>
          </el-upload>
        </el-form-item>
      </div>
      <div
        style="
          display: flex;
          box-sizing: border-box;
          padding-left: 170px;
          margin-top: 20px;
        "
      >
        <el-button
          style="width: 144px; height: 32px; line-height: 6px"
          type="primary"
          @click="_handleClickSubmit"
        >
          {{ $t('incoming_button2') }}
        </el-button>
      </div>
    </el-form>
    <el-dialog
      :close-on-click-modal="false"
      :visible="showDetail"
      :title="$t('do_payment_title5')"
      @close="showDetail = false"
      width="1000px"
    >
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDetail = false">
          {{ $t('system_settings_text15') }}
        </el-button>
        <el-button
          :loading="submit_loading"
          type="primary"
          @click="_getHandleSubmit"
        >
          {{ $t('system_settings_text16') }}
        </el-button>
      </span>
      <el-descriptions
        :labelStyle="{ fontWeight: 'bold', color: '#000' }"
        :column="2"
      >
        <el-descriptions-item :label="$t('do_payment_title6')">
          -
        </el-descriptions-item>
        <el-descriptions-item :label="$t('do_payment_title7')">
          -
        </el-descriptions-item>
        <el-descriptions-item :label="$t('do_payment_title8')">
          {{ payer || '-' }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('do_payment_title9')">
          {{ current_account && current_account.accountNo }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('do_payment_title10')">
          {{ paymentAccount || '-' }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('withdraw_title6')">
          -
        </el-descriptions-item>
        <el-descriptions-item :label="$t('do_payment_title11')">
          {{ (current_account && current_account.accountName) || '-' }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('withdraw_title7')">
          {{ form.currency || '-' }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('withdraw_title9')">
          {{ form.amount || '-' }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('withdraw_title10')">
          {{ form.feeType }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('withdraw_title11')">
          {{ form.feeCurrency || '-' }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('do_payment_title12')">
          {{ form.feeType === 'BEN' ? '0' : service_charge }}
        </el-descriptions-item>
        <el-descriptions-item :label="`${$t('do_payment_title13')}SWIFT`">
          {{ current_account && current_account.swiftCode }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('do_payment_title14')">
          -
        </el-descriptions-item>
        <el-descriptions-item :label="`${$t('do_payment_title15')}SWIFT`">
          -
        </el-descriptions-item>
        <el-descriptions-item :label="$t('withdraw_title14')">
          {{ form.usageType || '-' }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('withdraw_title16')">
          {{ form.remark || '-' }}
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import mixins from './mixins';
import {
  getAccounts,
  getAllCountries,
  getWithdrawFee,
  getWithdraw,
  getFeeList,
} from 'views/main/withdraw/api';
import { getAllAcc } from 'views/main/home-main/api';
import _ from 'lodash';
import config from '@/config';
export default {
  name: 'do-payment',
  mixins: [mixins],
  data() {
    return {
      fileAction: `${config.baseApi}/file-api/file-server/saas/add`,
      fileList: [],
      token: '',
      current_account: null, // 选择账户
      account_list: [],
      submit_loading: false,
      currency_list: [],
      balance1: '',
      service_charge: '', //手续费
      balance2: '',
      payer: '',
      hainaName: '',
      vaName: '',
      showDetail: false,
      tradeId: '',
      form: {
        accountId: '',
        currency: '',
        amount: '',
        feeCurrency: '',
        usageType: '',
        remark: '',
        feeType: 'OUR',
        fileUrl: '',
      },
      hainaAccount: '',
      vaAccount: '',
      paymentAccount: '',
      receivedAmount: '',
    };
  },
  mounted() {
    // 获取可提现账户列表
    this._getAccounts();
    this._getCurrencyList();
  },
  methods: {
    debounce_input_change: _.debounce(function () {
      this.getFee();
    }, 500),
    // 超出
    handleExceed(file, fileList) {
      if (fileList.length > 0) {
        this.$message.warning(this.$t('do_payment_title16'));
      }
    },
    // 请求接口
    async getFee() {
      if (this.form.currency && this.form.accountId && this.form.amount) {
        this.service_charge = `${this.$t('do_payment_title17')}...`;
        const { fee, tradeId, receivedAmount } = await getWithdrawFee({
          currency: this.form.currency,
          accountId: this.form.accountId,
          type: this.form.feeType,
          amount: this.form.amount,
          feeName: '付款',
        });
        this.service_charge = fee;
        this.tradeId = tradeId;
        this.receivedAmount = receivedAmount;
      }
    },
    /**
     * 文件上传之前判断类型是否正确
     */
    // 文件上传成功fn
    handleSuccess(response) {
      if (response.code !== 100200) {
        this.$message.error(response.dataSet || this.$t('do_payment_title18'));
        this.fileList = [];
        this.form = { ...this.form, fileUrl: '' };
      }
      this.form = { ...this.form, fileUrl: response.dataSet.path };
    },
    // getAllCountries
    async _getAllCountries() {
      this.all_countries = await getAllCountries();
    },
    // 提现到fn
    handleWithdraw(v) {
      this.current_account = this.account_list.filter(item => item.id === v)[0];
    },
    async _getFeeList(status, feeCurrency) {
      const _params = { feeCurrency, companyId: this.$store.state.companyId };

      if (status === 1) {
        _params.feeName = '提现';
      } else if (status === 2) {
        _params.feeName = '付款';
      }
      const res = await getFeeList(_params);
      return res;
    },
    // 选择币种
    async handleCurrencySelect(v, t) {
      if (t === '1') {
        this.balance1 = this.currency_list.find(o => o.currency === v)[
          'availableAmount'
        ];
      }
      if (t === '2') {
        const list = await this._getFeeList(2, v);
        if (!list.length) {
          this.form.feeCurrency = '';
          this.balance2 = '';
          return this.$message.warning('未配置手续费币种');
        }

        this.balance2 = this.currency_list.find(o => o.currency === v)[
          'balance'
        ];
      }
      // 手续费：当手续费币种选择非CNH和HKD时，手续费应该按照400HKD换算成选择的币种金额
      await this.getFee();
    },
    // 获取实时金额
    handleRealTimeBalance() {
      this.balance1 = this.currency_list.find(
        item => item.currency === this.form.currency,
      )['availableAmount'];
      this.balance2 = this.currency_list.find(
        item => item.currency === this.form.feeCurrency,
      )['balance'];
    },
    // 获取币种
    async _getCurrencyList() {
      const _params = {};
      this.currency_list = await getAllAcc(_params);
    },
    // 获取可提现账户
    async _getAccounts() {
      const _params = {
        accountType: 2,
      };
      const { list, hainaName, vaName, hainaAccount, vaAccount } =
        await getAccounts(_params);
      this.account_list = list;
      this.hainaName = hainaName;
      this.vaName = vaName;
      this.hainaAccount = hainaAccount;
      this.vaAccount = vaAccount;
      this.payer = hainaName;
    },
    // 提现按钮回调fn
    _handleClickSubmit() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          if (this.payer === this.hainaName) {
            this.paymentAccount = this.hainaAccount;
          } else if (this.payer === this.vaName) {
            this.paymentAccount = '79148' + this.vaAccount;
          }
          this.showDetail = true;
        } else {
          return false;
        }
      });
    },
    // 提现回调函数
    _getHandleSubmit() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          try {
            this.submit_loading = true;
            const _params = {
              ...this.form,
              payName: this.payer,
              type: '3',
              tradeId: this.tradeId,
            };
            await getWithdraw(_params);
            await this._getCurrencyList();
            this.handleRealTimeBalance();
            this.$message.success(this.$t('do_payment_title19'));
            this.submit_loading = false;
            this.$refs.form.resetFields();
            this.$emit('tabChange', 'third');
          } catch (err) {
            console.error(err);
          } finally {
            this.submit_loading = false;
            this.showDetail = false;
          }
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    'form.currency': function (newVal) {
      this.form.feeCurrency = newVal;
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
}
.flex-content {
  display: flex;
  flex-direction: column;
}
.content {
  width: 100%;
  box-sizing: border-box;
  padding-left: 40px;
  .acc-info-box {
    width: 50%;
  }
}
</style>
