import { commonReg } from '@/utils/utils';

export default {
  data() {
    return {
      tabNo: 'first',
      // tab1的rules

      radio_value: 'OUR',
    };
  },
  computed: {
    rules() {
      const commonCheck2 = (rule, value, callback) => {
        if (value === '') {
          return callback();
        } else {
          if (!commonReg.test(value)) {
            return callback(new Error(this.$t('COMMON_ERR2')));
          }
        }
        return callback();
      };
      return {
        accountId: [
          {
            required: true,
            message: this.$t('COMMON_ERR4'),
            trigger: 'change',
          },
        ],
        currency: [
          {
            required: true,
            message: this.$t('COMMON_ERR4'),
            trigger: 'change',
          },
        ],
        amount: [
          { required: true, message: this.$t('COMMON_ERR1'), trigger: 'blur' },
        ],
        feeType: [
          { required: true, message: this.$t('COMMON_ERR1'), trigger: 'blur' },
        ],
        feeCurrency: [
          {
            required: true,
            message: this.$t('COMMON_ERR4'),
            trigger: 'change',
          },
        ],
        usageType: [
          {
            required: true,
            message: this.$t('COMMON_ERR4'),
            trigger: 'change',
          },
        ],
        remark: [
          {
            validator: commonCheck2,
            trigger: 'blur',
          },
        ],
      };
    },
    options() {
      return [
        { label: this.$t('bind_account_title33'), value: 'OUR' },
        { label: this.$t('bind_account_title34'), value: 'SHA' },
        // { label: this.$t('bind_account_title35'), value: 'BEN' },
      ];
    },
    // productOptions() {
    //   if (typeof this.product === 'string') {
    //     return {
    //       tab1: false,
    //       tab2: false,
    //       tab3: false,
    //     };
    //   }
    //   return {
    //     tab1: this.product.withdrawalStatus === 1,
    //     tab2: this.product.payStatus === 1,
    //     tab3: this.product.inStatus === 1,
    //   };
    // },
  },
};
