<template>
  <div class="container">
    <el-card>
      <el-tabs v-model="tabNo" @tab-click="handleTabChange">
        <!--tab1-->
        <el-tab-pane :label="$t('withdraw_tab1')" name="first">
          <template>
            <el-form
              inline
              size="small"
              label-position="right"
              label-width="220px"
              :model="form"
              :rules="rules"
              ref="form"
            >
              <p class="header">{{ $t('withdraw_title1') }}</p>
              <div class="content">
                <el-form-item :label="$t('withdraw_title2')" prop="accountId">
                  <el-select
                    v-model="form.accountId"
                    :placeholder="$t('COMMON_ERR4')"
                    @change="handleWithdraw"
                    style="width: 300px"
                  >
                    <el-option
                      v-for="acc in account_list"
                      :key="acc.id"
                      :value="acc.id"
                      :label="acc.accountName"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <div class="acc-info-box" v-if="form.accountId">
                  <el-form-item :label="`${$t('withdraw_title3')}:`">
                    <el-input
                      disabled
                      :value="current_account && current_account.accountName"
                      style="width: 300px"
                    />
                  </el-form-item>
                  <el-form-item :label="`${$t('withdraw_title4')}:`">
                    <el-input
                      disabled
                      :value="current_account && current_account.accountNo"
                      style="width: 300px"
                    />
                  </el-form-item>
                  <el-form-item :label="`${$t('withdraw_title5')}:`">
                    <el-input
                      disabled
                      :value="current_account && current_account.address"
                      style="width: 300px"
                    />
                  </el-form-item>
                  <el-form-item label="SWIFT:">
                    <el-input
                      disabled
                      :value="current_account && current_account.swiftCode"
                      style="width: 300px"
                    />
                  </el-form-item>
                  <el-form-item :label="`${$t('home_page_title29')}:`">
                    <el-input
                      disabled
                      :value="current_account && current_account.accountBank"
                      style="width: 300px"
                    />
                  </el-form-item>
                  <el-form-item :label="`${$t('home_page_title30')}:`">
                    <el-input
                      disabled
                      :value="current_account && current_account.bankAddress"
                      style="width: 300px"
                    />
                  </el-form-item>
                </div>
              </div>
              <p class="header">{{ $t('withdraw_title6') }}</p>
              <div class="content flex-content">
                <el-form-item :label="$t('withdraw_title7')" prop="currency">
                  <el-select
                    v-model="form.currency"
                    :placeholder="$t('COMMON_ERR4')"
                    @change="v => handleCurrencySelect(v, '1')"
                    style="width: 300px"
                  >
                    <el-option
                      v-for="c in currency_list"
                      :label="c.currency"
                      :key="c.currency"
                      :value="c.currency"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('withdraw_title8')">
                  <el-input disabled :value="balance1" style="width: 300px" />
                </el-form-item>
                <el-form-item :label="$t('withdraw_title9')" prop="amount">
                  <el-input
                    :placeholder="$t('COMMON_ERR1')"
                    v-model="form.amount"
                    onkeyup="value=value.match(/\d+\.?\d{0,20}/);this.dispatchEvent(new Event('input'))"
                    @input="debounce_input_change"
                    style="width: 300px"
                  />
                </el-form-item>
                <el-form-item :label="$t('withdraw_title10')" prop="feeType">
                  <a-radio-group
                    :options="options"
                    v-model="form.feeType"
                    @input="() => getFee()"
                  />
                </el-form-item>
                <el-form-item :label="$t('withdraw_title11')">
                  <el-select
                    :placeholder="$t('COMMON_ERR4')"
                    v-model="form.feeCurrency"
                    @change="v => handleCurrencySelect(v, '2')"
                    disabled
                    style="width: 300px"
                  >
                    <el-option
                      v-for="c in currency_list"
                      :label="c.currency"
                      :key="c.currency"
                      :value="c.currency"
                    ></el-option>
                  </el-select>
                  <!-- <el-select
                    :placeholder="$t('COMMON_ERR4')"
                    v-model="form.feeCurrency"
                    @change="v => handleCurrencySelect(v, '2')"
                    :disabled="form.feeType === 'BEN'"
                  >
                    <el-option
                      v-for="c in currency_list"
                      :label="c.currency"
                      :key="c.currency"
                      :value="c.currency"
                    ></el-option>
                  </el-select> -->
                </el-form-item>
                <el-form-item :label="$t('withdraw_title12')">
                  <el-input
                    :value="form.feeType === 'BEN' ? '0' : balance2"
                    disabled
                    style="width: 300px"
                  />
                </el-form-item>
                <el-form-item :label="$t('withdraw_title13')">
                  <el-input
                    :value="form.feeType === 'BEN' ? '0' : service_charge"
                    disabled
                    style="width: 300px"
                  />
                </el-form-item>
                <!-- <el-form-item label="预计到账金额">
                  <el-input
                    :value="receivedAmount"
                    disabled
                    style="width: 300px"
                  />
                </el-form-item> -->
                <el-form-item :label="$t('withdraw_title14')" prop="usageType">
                  <el-select
                    v-model="form.usageType"
                    :placeholder="$t('COMMON_ERR4')"
                    style="width: 300px"
                  >
                    <el-option
                      value="一般商业用途"
                      :label="$t('withdraw_title15')"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('withdraw_title16')" prop="remark">
                  <el-input
                    v-model="form.remark"
                    :placeholder="$t('COMMON_ERR1')"
                    style="width: 300px"
                  />
                </el-form-item>
              </div>
              <p class="header">{{ $t('withdraw_title17') }}</p>
              <div class="content">
                <p>
                  <span style="color: red">*</span>
                  <span style="color: #606266">
                    {{ $t('withdraw_title18') }}
                  </span>
                </p>
                <el-radio v-model="payer" :label="hainaName">
                  {{ hainaName }}
                </el-radio>
                <el-radio v-if="vaName" v-model="payer" :label="vaName">
                  {{ vaName }}
                </el-radio>
              </div>
              <div
                style="
                  display: flex;
                  box-sizing: border-box;
                  padding-left: 170px;
                  margin-top: 20px;
                "
              >
                <el-button
                  style="width: 144px; height: 32px; line-height: 6px"
                  type="primary"
                  @click="_handleClickSubmit"
                >
                  {{ $t('incoming_button2') }}
                </el-button>
              </div>
            </el-form>
          </template>
        </el-tab-pane>
        <!--tab2-->
        <el-tab-pane :label="$t('withdraw_tab2')" name="second">
          <template>
            <do-payment
              v-if="tabNo === 'second'"
              @tabChange="
                tab => {
                  this.tabNo = tab;
                  this._getWithdrawRecord();
                }
              "
            />
          </template>
        </el-tab-pane>
        <el-tab-pane label="资金跨境" name="fifth">
          <template>
            <cross-capital />
          </template>
        </el-tab-pane>
        <!--tab3-->
        <el-tab-pane :label="$t('withdraw_tab3')" name="third">
          <withdraw-record
            :table-loading="tableLoading"
            :data="withdraw_record"
            :pages="record_pages"
            @handlePageChange="_handleWithdrawPageChange"
          />
        </el-tab-pane>
        <!--tab4-->
        <el-tab-pane :label="$t('withdraw_tab4')" name="fourth">
          <bind-account
            :table-loading="tableLoading"
            :account-list="withdraw_account_list"
            :withdraw-pages="withdraw_pages"
            @handleBindPageChange="handleBindPageChange"
            :token="token"
            @handleDelete="_handleDelete"
            :all-countries="all_countries"
          />
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <el-dialog
      :close-on-click-modal="false"
      :visible="showDetail"
      :title="$t('do_payment_title5')"
      @close="showDetail = false"
      width="1000px"
    >
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDetail = false">
          {{ $t('system_settings_text15') }}
        </el-button>
        <el-button
          :loading="submit_loading"
          type="primary"
          @click="_getHandleSubmit"
        >
          {{ $t('system_settings_text16') }}
        </el-button>
      </span>
      <el-descriptions :column="2">
        <el-descriptions-item :label="$t('do_payment_title6')">
          -
        </el-descriptions-item>
        <el-descriptions-item :label="$t('do_payment_title7')">
          -
        </el-descriptions-item>
        <el-descriptions-item :label="$t('do_payment_title8')">
          {{ payer || '-' }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('do_payment_title9')">
          {{ current_account && current_account.accountNo }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('do_payment_title10')">
          {{ paymentAccount || '-' }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('withdraw_title6')">
          -
        </el-descriptions-item>
        <el-descriptions-item :label="$t('do_payment_title11')">
          {{ (current_account && current_account.accountName) || '-' }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('withdraw_title7')">
          {{ form.currency || '-' }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('withdraw_title9')">
          {{ form.amount || '-' }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('withdraw_title10')">
          {{ form.feeType }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('withdraw_title11')">
          {{ form.feeCurrency || '-' }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('do_payment_title12')">
          {{ form.feeType === 'BEN' ? '0' : service_charge }}
        </el-descriptions-item>
        <el-descriptions-item :label="`${$t('do_payment_title13')}SWIFT`">
          {{ current_account && current_account.swiftCode }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('do_payment_title14')">
          -
        </el-descriptions-item>
        <el-descriptions-item :label="`${$t('do_payment_title15')}SWIFT`">
          -
        </el-descriptions-item>
        <el-descriptions-item :label="$t('withdraw_title14')">
          {{ form.usageType || '-' }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('withdraw_title16')">
          {{ form.remark || '-' }}
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import mixins from './mixins';
import BindAccount from 'views/main/withdraw/components/bind-account/bind-account.vue'; // 绑定账户tab内容
import WithdrawRecord from 'views/main/withdraw/components/withdraw-record/withdraw-record.vue';
import DoPayment from 'views/main/withdraw/components/do-payment/do-payment.vue';
import CrossCapital from 'views/main/withdraw/components/cross-capital/cross-capital.vue';
import _ from 'lodash';
import {
  getAccounts,
  getWithdraw,
  getWithdrawRecordList,
  getWithdrawFee,
  getDeleteAcc,
  getAllCountries,
  getWithdrawAccList,
  getProduct,
  getFeeList,
} from 'views/main/withdraw/api';
import { getAllAcc } from 'views/main/home-main/api';

export default {
  name: 'withdraw',
  mixins: [mixins],
  components: { CrossCapital, BindAccount, WithdrawRecord, DoPayment },
  data() {
    return {
      token: '',
      tradeId: '',
      form: {
        accountId: '',
        currency: '',
        amount: '',
        feeCurrency: '',
        usageType: '',
        remark: '',
        feeType: 'OUR',
      },
      all_countries: [],
      currency_list: [],
      account_list: [],
      withdraw_account_list: [], // tab4 提现列表
      withdraw_record: [],
      withdraw_pages: {
        pageSize: 10,
        pageNum: 1,
        total: 0,
      },
      // 提现记录pagination
      record_pages: {
        pageSize: 10,
        pageNum: 1,
        total: 0,
      },
      current_account: null, // 选择账户
      submit_loading: false,
      balance1: '',
      balance2: '',
      service_charge: '', //手续费
      hainaName: '',
      vaName: '',
      payer: '',
      showDetail: false,
      tableLoading: false,
      hainaAccount: '',
      vaAccount: '',
      paymentAccount: '',
      tabNo: 'first',
      product: [],
      feeList1: [],
      feeList2: [],
      receivedAmount: '',
    };
  },
  mounted() {
    this.token = this.$storage.getItem('token');
    // 获取可提现账户列表
    this._getAccounts();
    // // 获取提现记录
    // this._getWithdrawRecord();
    this._getCurrencyList(); // 1
    this._getAllCountries();
    // // 添加收款账户列表
    // this._getWithdrawAccList();
    // this._getProduct();
  },
  methods: {
    async _getFeeList(status, feeCurrency) {
      const _params = { feeCurrency, companyId: this.$store.state.companyId };

      if (status === 1) {
        _params.feeName = '提现';
      } else if (status === 2) {
        _params.feeName = '付款';
      }
      const res = await getFeeList(_params);
      return res;
    },
    async _getProduct() {
      this.product = await getProduct({
        companyId: this.$storage.getItem('companyId'),
      });
    },
    handleBindPageChange(p) {
      this.withdraw_pages = { ...this.withdraw_pages, pageNum: p };
      this._getWithdrawAccList();
    },
    // tab4数据
    async _getWithdrawAccList() {
      this.tableLoading = true;
      const { pageSize, pageNum } = this.withdraw_pages;
      const _params = { pageSize, pageNum };
      const { dataSet, pages } = await getWithdrawAccList(_params);
      this.withdraw_account_list = dataSet;
      this.withdraw_pages = pages;
      this.tableLoading = false;
    },
    // getAllCountries
    async _getAllCountries() {
      this.all_countries = await getAllCountries();
    },

    // 获取可提现账户
    async _getAccounts() {
      const _params = {
        accountType: 1,
      };
      this.tableLoading = true;
      const { list, hainaName, vaName, hainaAccount, vaAccount } =
        await getAccounts(_params);
      this.account_list = list;
      this.hainaName = hainaName;
      this.vaName = vaName;
      this.payer = hainaName;
      this.hainaAccount = hainaAccount;
      this.vaAccount = vaAccount;
      this.tableLoading = false;
    },
    // 删除账户
    async _handleDelete(params) {
      await getDeleteAcc(params);
      this.$message.success('删除成功');
      await this._getAccounts();
      await this._getWithdrawAccList();
    },
    // 提现按钮回调fn
    _handleClickSubmit() {
      this.$refs['form'].validate(async valid => {
        if (!valid) {
          return false;
        }

        if (this.payer === this.hainaName) {
          this.paymentAccount = this.hainaAccount;
        } else if (this.payer === this.vaName) {
          this.paymentAccount = '79148' + this.vaAccount;
        }

        this.showDetail = true;
      });
    },
    handleTabChange(tab) {
      this.withdraw_pages = {
        pageSize: 10,
        pageNum: 1,
        total: 0,
      };
      if (tab.name === 'first') {
        this._getAccounts();
        this._getCurrencyList();
      }
      if (tab.name === 'third') {
        // 获取提现记录
        this._getWithdrawRecord();
      }
      if (tab.name === 'fourth') {
        // 添加收款账户列表
        this._getWithdrawAccList();
      }
    },
    // 提现confirm函数
    _getHandleSubmit() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          try {
            this.submit_loading = true;
            const _params = {
              ...this.form,
              payName: this.payer,
              tradeId: this.tradeId,
            };
            await getWithdraw(_params);
            await this._getCurrencyList();
            this.handleRealTimeBalance();
            this.$message.success('操作成功!');
            this.submit_loading = false;
            this.$refs.form.resetFields();
            this.handleTabChange('third');
          } catch (err) {
            console.error(err);
          } finally {
            this.submit_loading = false;
            this.showDetail = false;
          }
        } else {
          return false;
        }
      });
    },
    // 获取提现记录
    async _getWithdrawRecord() {
      const _params = { ...this.record_pages };
      delete _params['total'];
      this.tableLoading = true;
      const { dataSet, pages } = await getWithdrawRecordList(_params);
      dataSet.forEach(
        (o, i) => (o['index_'] = (pages.pageNum - 1) * 10 + i + 1),
      );
      this.tableLoading = false;
      this.withdraw_record = dataSet;
      this.record_pages = pages;
    },
    // 获取币种
    async _getCurrencyList() {
      const _params = {};
      this.currency_list = await getAllAcc(_params);
    },
    // 选择币种
    async handleCurrencySelect(v, t) {
      if (t === '1') {
        this.balance1 = this.currency_list.find(o => o.currency === v)[
          'availableAmount'
        ];
      }
      if (t === '2') {
        const list = await this._getFeeList(1, v);
        if (!list.length) {
          this.form.feeCurrency = '';
          this.balance2 = '';
          return this.$message.warning('未配置手续费币种');
        }

        this.balance2 = this.currency_list.find(o => o.currency === v)[
          'balance'
        ];
      }
      // 手续费：当手续费币种选择非CNH和HKD时，手续费应该按照400HKD换算成选择的币种金额
      await this.getFee();
    },
    // 获取实时金额
    handleRealTimeBalance() {
      this.balance1 = this.currency_list.find(
        item => item.currency === this.form.currency,
      )['availableAmount'];
      this.balance2 = this.currency_list.find(
        item => item.currency === this.form.feeCurrency,
      )['balance'];
    },
    // 请求接口
    async getFee() {
      if (this.form.currency && this.form.accountId && this.form.amount) {
        this.service_charge = '查询中，请稍后...';
        const { fee, tradeId, receivedAmount } = await getWithdrawFee({
          currency: this.form.currency,
          // feeCurrency: this.form.feeCurrency,
          accountId: this.form.accountId,
          type: this.form.feeType,
          amount: this.form.amount,
          feeName: '提现',
        });
        this.service_charge = fee;
        this.tradeId = tradeId;
        this.receivedAmount = receivedAmount;
      }
    },
    debounce_input_change: _.debounce(function () {
      this.getFee();
    }, 500),
    // 提现到fn
    handleWithdraw(v) {
      this.current_account = this.account_list.filter(item => item.id === v)[0];
    },
    // 提现记录page change
    _handleWithdrawPageChange(pageNum) {
      this.record_pages = { ...this.record_pages, pageNum: pageNum };
      this._getWithdrawRecord();
    },
  },
  watch: {
    'form.currency': function (newVal) {
      this.form.feeCurrency = newVal;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  padding: 16px 24px 0 24px;
  .header {
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.65);
  }
  .flex-content {
    display: flex;
    flex-direction: column;
  }
  .content {
    width: 100%;
    box-sizing: border-box;
    padding-left: 40px;
    .acc-info-box {
      width: 50%;
    }
  }
}
</style>
