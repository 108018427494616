import storage from '@/utils/storage';
import { commonReg } from '@/utils/utils';

export default {
  data() {
    return {
      // tab1 radio
      uploadHeader: { Authorization: storage.getItem('token') },
    };
  },
  computed: {
    rules() {
      const commonCheck2 = (rule, value, callback) => {
        if (value === '') {
          return callback();
        } else {
          if (!commonReg.test(value)) {
            return callback(new Error(this.$t('COMMON_ERR2')));
          }
        }
        return callback();
      };
      return {
        accountId: [
          { required: true, message: this.$t('COMMON_ERR1'), trigger: 'blur' },
        ],
        currency: [
          { required: true, message: this.$t('COMMON_ERR1'), trigger: 'blur' },
        ],
        amount: [
          { required: true, message: this.$t('COMMON_ERR1'), trigger: 'blur' },
        ],
        feeType: [
          { required: true, message: this.$t('COMMON_ERR1'), trigger: 'blur' },
        ],
        feeCurrency: [
          { required: true, message: this.$t('COMMON_ERR1'), trigger: 'blur' },
        ],
        usageType: [
          { required: true, message: this.$t('COMMON_ERR1'), trigger: 'blur' },
        ],
        fileUrl: [{ required: true }],
        remark: [
          {
            validator: commonCheck2,
            trigger: 'blur',
          },
        ],
      };
    },
    options() {
      return [
        { label: this.$t('bind_account_title33'), value: 'OUR' },
        { label: this.$t('bind_account_title34'), value: 'SHA' },
        // { label: this.$t('bind_account_title35'), value: 'BEN' },
      ];
    },
  },
};
