export default {
  COMMON_ERR1: '内容不能为空',
  COMMON_ERR2: '内容与规则不匹配',
  COMMON_ERR3: '请上传内容',
  COMMON_ERR4: '请选择内容',
  /*----------------登录页面------------------------*/
  site_title: '商户自助服务平台',
  login_username_placeholder: '请输入用户名',
  login_password_placeholder: '请输入登陆密码',
  login_code_placeholder: '请输入验证码',
  login_presentation_start: '还没有账号，',
  login_presentation_end: '马上注册',
  login_forgotten_password: '忘记密码',
  login_button: '登录',
  login_success: '登录成功',
  exit_success: '退出成功',
  site_logo_title: '乐想汇',
  /*----------------登录页面结束------------------------*/
  /*----------------注册页面------------------------*/
  // 注册页面-----左边部分
  register_card_title: '用户注册',
  register_title: '注册',
  register_form1: '企业注册所在国家/地区',
  register_form1_placeholder: '请输入企业注册所在国家/地区',
  register_form2: '邮箱',
  register_form2_placeholder: '请输入邮箱',
  register_form2_validate: '请输入正确格式的邮箱!',
  register_form3: '手机号码',
  register_form3_placeholder: '请输入手机号码',
  register_form4: '密码',
  register_form4_placeholder: '请输入密码',
  register_form4_validate: '密码应为包含数字、大小写字母任意两种 8-20个字符',
  register_form5: '确认密码',
  register_form5_placeholder: '请再次输入密码',
  register_form5_validate: '两次输入不一致!',
  register_form6: '验证码',
  register_form6_placeholder: '请输入验证码',
  register_code_button: '获取验证码',
  register_code_time: ' 秒后重新发送',
  register_form7: '推荐编码',
  register_form7_placeholder: '请输入推荐编码',
  register_user_agreement: '用户协议',
  register_button: '已阅读且同意以上协议，并完成注册',
  register_message1: '已有账号，',
  register_message2: '马上登录',
  // 注册页面-----右边部分
  register_sub_title1: '地区',
  register_sub1_content1: '所在地区未在选择范围内请选择其他',
  register_sub_title2: '手机号码',
  register_sub2_content1: '请检查手机号码是否正确，是否存在欠费',
  register_sub2_content2: '检查短信箱中的垃圾短信，或被第三方软件拦截',
  register_sub2_content3: '若仍未收到，请尝试重新发送',
  register_sub_title3: '邮件注意事项',
  register_sub3_content1: '请检查邮箱地址是否正确',
  register_sub3_content2: '检查邮箱中的垃圾邮件箱',
  register_sub3_content3: '若仍未收到，请尝试重新发送',
  register_sub_title4: '密码必须包含',
  register_sub4_content1: '英文开头 包含数字、大小写字母任意两种 8-20字符',
  /*----------------注册页面结束------------------------*/

  /*----------------忘记密码页面------------------------*/
  forget_password_title: '忘记密码',
  forget_form1_placeholder: '请输入用户名',
  forget_form2_placeholder: '请输入图片验证码',
  forget_form3_placeholder: '请输入验证码',
  forget_code_font: '获取验证码',
  forget_last_time: ' 秒后重新获取',
  forget_form4_placeholder: '请输入新密码',
  forget_form5_placeholder: '确认密码',
  forget_rule_password1: '密码不能为空',
  forget_rule_password2: '请输入6-18位并且必须包含数字和字母的密码',
  forget_rule_password3: '请再次输入密码',
  forget_rule_password4: '两次输入密码不一致',
  forget_button: '确认',
  forget_code_message: '发送成功，请注意查收',
  forget_success_message: '密码修改成功',
  /*----------------忘记密码页面结束------------------------*/

  /*----------------未进件页面(incoming-unit)------------------------*/
  incoming_step_title1: '主体资料录入',
  incoming_step_title2: '入网进件审核中',
  incoming_step_title3: '审核结果',
  incoming_title: '主体类型',
  incoming_title_type1: '大陆企业',
  incoming_title_type2: '香港企业',
  incoming_title_type3: '其他国家地区企业',
  incoming_company_title: '企业信息(必填)',
  incoming_company_form1: '营业执照图片',
  incoming_company_form2: '企业证件图片',
  incoming_company_form3: '公司注册国家地区',
  incoming_company_form4: '企业证书图片',
  incoming_company_form5: '公司名称',
  incoming_company_form6: '公司英文名称',
  incoming_company_form7: '社会统一信用代码',
  incoming_company_form8: '注册地址',
  incoming_company_form9: '经营地址',
  incoming_company_form10: '成立日期',
  incoming_company_form11: '注册资本',
  incoming_company_form12: '有效期',
  incoming_company_form13: '公司性质',
  incoming_company_form14: '注册证书编号',
  incoming_company_form15: '商业登记证编号',
  incoming_company_form16: '商业登记证有效期',
  incoming_company_form17: '公司注册地址（同商业登记证注册地址一致）',
  incoming_company_form18: '公司实际经营地址',
  incoming_company_form19: '企业证件号',
  incoming_company_form20: '证件有效期',
  incoming_company_form21: '公司注册地址（同证件注册地址一致）',
  incoming_company_form22: '公司实际经营地址',
  incoming_company_form23: '公司营业范围',
  incoming_company_form24: '公司官网/经营店铺网址',
  incoming_company_form25: '股权架构图',
  incoming_company_form26: '周年申报表',
  incoming_company_form27: '法团成立表格',

  incoming_company_upload1: '点击上传',
  incoming_company_upload_tip1: '营业执照图片',
  incoming_company_upload_tip2: '企业证件图片',
  incoming_company_upload_tip3:
    '请确认信息无误，上传图片清晰可见，图片支持jpg、png、jpeg，文件大小不超过5MB',
  incoming_company_upload_tip4: '公司注册证书图片',
  incoming_company_upload_tip5: '商业登记证图片',
  incoming_company_upload_tip6: '只能上传 图片/pdf 文件，且不超过15MB',
  incoming_company_select_placeholder: '请选择',
  incoming_company_input_placeholder: '请输入内容',
  incoming_company_date_placeholder1: '至',
  incoming_company_date_placeholder2: '开始日期',
  incoming_company_date_placeholder3: '结束日期',

  incoming_director_title1: '法定代表人',
  incoming_director_title2: '董事',
  incoming_director_title_end: '信息(必填)',
  incoming_director_form1: '证件类型',
  incoming_director_form2: '第二代居民身份证',
  incoming_director_form3: '护照',
  incoming_director_form4: '香港永久性居民身份证',
  incoming_director_form5: '法定代表人证件图片',
  incoming_director_form6: '董事证件图片',
  incoming_director_form7: '身份证人像面',
  incoming_director_form8: '身份证国徽面',
  incoming_director_form9: '证件签发国家地区',
  incoming_director_form10: '护照首页图片',
  incoming_director_form11: '企业法人姓名',
  incoming_director_form12: '董事姓名',
  incoming_director_form13: '企业法人英文名称',
  incoming_director_form14: '董事英文名称',
  incoming_director_form15: '证件号码',
  incoming_director_form16: '性别',
  incoming_director_form17: '男',
  incoming_director_form18: '女',
  incoming_director_form19: '出生日期',
  incoming_director_form20: '证件有效期',
  incoming_director_form21: '非长期有效',
  incoming_director_form22: '长期有效',
  incoming_director_form23: '法人地址',
  incoming_director_form24: '地址',
  incoming_director_form25: '地址证明',

  incoming_beneficiary_title: '受益人信息(必填)',
  incoming_beneficiary_form1: '法人为唯一受益人',
  incoming_beneficiary_form2: '法人非唯一受益人',
  incoming_beneficiary_form3: '受益人',
  incoming_beneficiary_form4: '添加',
  incoming_beneficiary_form5: '删除',
  incoming_beneficiary_form6: '受益人证件类型',
  incoming_beneficiary_form7: '第二代居民身份证',
  incoming_beneficiary_form8: '护照',
  incoming_beneficiary_form9: '香港永久性居民身份证',
  incoming_beneficiary_form10: '受益人证件图片',
  incoming_beneficiary_form11: '请上传',
  incoming_beneficiary_form12: '身份证照片页图片',
  incoming_beneficiary_form13: '受益人姓名',
  incoming_beneficiary_form14: '受益人英文名称',
  incoming_beneficiary_form15: '请保留一条',
  incoming_beneficiary_form16: '受益人最多存在10个',

  incoming_agent_title: '代理人信息(选填)',
  incoming_agent_form1: '注册人与企业关系',
  incoming_agent_form2: '代理人',
  incoming_agent_form3: '法人/董事',
  incoming_agent_form4: '代理人证件类型',
  incoming_agent_form5: '代理人证件图片',
  incoming_agent_form6: '手持身份证图片',
  incoming_agent_form7: '代理人姓名',
  incoming_agent_form8: '代理人英文名称',
  incoming_agent_form9: '代理人证件地址',
  incoming_agent_form10: '代理人企业授权书',

  incoming_button1: '暂存',
  incoming_button2: '提交',
  incoming_message1: '您的入网进件信息正在审核中',
  incoming_message2:
    '我们会在1-2个工作日内完成审核，审核通过后系统将发送邮件通知到您的绑定邮箱',
  incoming_message3: '入网进件信息审核已通过',
  incoming_message4: '签署协议',
  incoming_message5: '您的入网进件信息审核未通过',
  incoming_message6: '请前往入网进件资料提交页面进行资料修改并重新提交审核',
  incoming_message7: '前往修改',

  /*----------------未进件页面结束(incoming-unit)------------------------*/

  /*----------------首页菜单------------------------*/

  index_menu1: '首页',
  index_menu2: '我的账户',
  index_menu3: '换汇服务',
  index_menu4: '全球付款',
  index_menu5: '货币假期',
  index_menu6: '系统设置',
  index_menu7: '资金入境',
  index_message: '修改成功',
  index_message2: '您好',
  index_message3: '修改密码',
  index_message4: '退出登录',
  /*----------------首页菜单结束------------------------*/

  /*----------------首页页面------------------------*/

  home_page_title1: '账户余额',
  home_page_title2: '截止时间',
  home_page_title3: '查看所有账户',
  home_page_title4: '我的账号',
  home_page_title5: '币种',
  home_page_title6: '余额',
  home_page_title7: '账户状态',
  home_page_title8: '操作',
  home_page_title9: '查看明细',
  home_page_title10: '提现',
  home_page_title11: '授信额度',
  home_page_title12: '详情',
  home_page_title13: '总额度',
  home_page_title14: '已占用额度',
  home_page_title15: '剩余额度',
  home_page_title16: '近期兑换记录',
  home_page_title17: '交易时间',
  home_page_title18: '货币对',
  home_page_title19: '卖出信息',
  home_page_title20: '买入信息',
  home_page_title21: '汇率',
  home_page_title22: '交割状态',
  home_page_title23: '待办事项',
  home_page_title24: '待交割数量',
  home_page_title25: '滞纳金笔数',
  home_page_title26: '货币假期信息',
  home_page_title27: '我知道了',
  home_page_title28:
    '请将充值金额转账到以下银行账户, 转账时请准确填写交易附言。',
  home_page_title29: '银行名称',
  home_page_title30: '银行地址',
  home_page_title31: '收款账户名称',
  home_page_title32: '收款账户地址',
  home_page_title33: '收款账户号码',
  home_page_title34: '附言',
  home_page_title35: '香港本地汇入手续费',
  home_page_title36: 'CNH、HKD、USD免费，其余币种100港币或等额外币',
  home_page_title37: '跨境汇入手续费',
  home_page_title38: '100港币或等额外币（含中国大陆汇入）',
  /*----------------首页页面结束------------------------*/

  /*----------------我的币种页面------------------------*/
  my_account_msg1: '乐想汇多币种账户',
  my_account_msg2: '可用余额',
  my_account_msg3: '受限余额',
  /*----------------我的币种页面结束------------------------*/

  /*----------------credit-record-detail pages------------------------*/

  credit_record_detail_msg1: '占用交易额度明细',
  credit_record_detail_msg2: '序号',
  credit_record_detail_msg3: '成交时间',
  credit_record_detail_msg4: '卖出',
  credit_record_detail_msg5: '买入',
  credit_record_detail_msg6: '交割日',
  credit_record_detail_msg7: '成交价格',
  credit_record_detail_msg8: '合约状态',
  credit_record_detail_msg9: '未交割',
  credit_record_detail_msg10: '已交割',
  credit_record_detail_msg11: '交割失败',
  credit_record_detail_msg12: '已违约',

  /*----------------credit-record-detail pages end------------------------*/

  /*----------------check-detail pages------------------------*/

  check_detail_msg1: '账户明细',
  check_detail_msg2: '日期',
  check_detail_msg3: '查询',
  check_detail_msg4: '下载',
  check_detail_msg5: '记账时间',
  check_detail_msg6: '收入',
  check_detail_msg7: '支出',
  check_detail_msg8: '初期余额',
  check_detail_msg9: '交易描述',
  check_detail_msg10: '备注',
  check_detail_msg11: '下载成功',

  /*----------------check-detail pages end------------------------*/

  /*----------------system-settings pages------------------------*/

  system_settings_btn: '人员管理',
  system_settings_btn2: '开发设置',
  system_settings_btn3: '添加用户',
  system_settings_text1: '客户管理员',
  system_settings_text2: '交易员',
  system_settings_text3: '查询员',
  system_settings_text4: '正常',
  system_settings_text5: '冻结',
  system_settings_text6: '编辑',
  system_settings_text7: '停用',
  system_settings_text8: '启用',
  system_settings_text9: '重置密码',
  system_settings_text10: '编辑用户',
  system_settings_text11: '添加用户',
  system_settings_text12: '姓名',
  system_settings_text13: '角色',
  system_settings_text14: '管理员',
  system_settings_text15: '取消',
  system_settings_text16: '确定',
  system_settings_text17: '确认重置当前用户密码?',
  system_settings_text18: '停用账户',
  system_settings_text19: '启用账户',
  system_settings_text20: '确认停用当前用户吗？',
  system_settings_text21: '确认启用当前用户吗？',
  system_settings_text22: '状态',

  /*----------------system-settings pages end------------------------*/

  /*----------------exchange-service pages------------------------*/

  exchange_service_tab1: '汇率查询',
  exchange_service_tab2: '发起兑换',
  exchange_service_tab3: '合约查询',
  exchange_service_tab4: '服务费查询',

  /*----------------exchange-service pages end------------------------*/

  /*----------------price-inquiry pages------------------------*/

  price_inquiry_text1: '汇率查询',
  price_inquiry_text2: '批量查询',
  price_inquiry_text3: '币种和交割日',
  price_inquiry_text4: '查询价格',

  /*----------------price-inquiry pages end------------------------*/

  /*----------------initlate-exchange pages------------------------*/

  initlate_exchange_text1: '现汇以支持7*24小时实时兑换',
  initlate_exchange_text2: '币种和交割日',
  initlate_exchange_text3: '参考汇率',
  initlate_exchange_text4: '交易方式',
  initlate_exchange_text5: '现汇',
  initlate_exchange_text6: '授信（可用额度：USD',
  initlate_exchange_text7: '金额',
  initlate_exchange_text8: '下一步',
  initlate_exchange_text9: '确认报价',
  initlate_exchange_text10: '确认兑换',
  initlate_exchange_text11: '本次使用交易额度',
  initlate_exchange_text12: '剩余交易额度',
  initlate_exchange_text13: '交易日',
  initlate_exchange_text14: '请输入金额',
  initlate_exchange_text15: '您的价格已过期，请重新获取!',
  initlate_exchange_text16: '交易编号',
  initlate_exchange_text17: '服务费',
  initlate_exchange_text18: '系统流水号',

  /*----------------initlate-exchange pages end------------------------*/

  /*----------------contract-query pages------------------------*/

  contract_query_title1: '全部',
  contract_query_title2: '交割日期',
  contract_query_title3: '导出数据',
  contract_query_title4: '产品类型',
  contract_query_title5: '信用',
  contract_query_title6: '货币对',

  /*----------------contract-query pages end------------------------*/

  /*----------------service-fee-query pages------------------------*/

  service_fee_query_title1: '支付状态',
  service_fee_query_title2: '待支付',
  service_fee_query_title3: '已支付',
  service_fee_query_title4: '已减免',
  service_fee_query_title5: '客户公司名称',
  service_fee_query_title6: '交易类型',
  service_fee_query_title7: '类型',
  service_fee_query_title8: '滞纳金',
  service_fee_query_title9: '违约金',
  service_fee_query_title10: '费用',
  service_fee_query_title11: '费用产生时间',

  /*----------------service-fee-query pages end------------------------*/

  /*----------------withdraw pages------------------------*/

  withdraw_tab1: '发起提现',
  withdraw_tab2: '发起付款',
  withdraw_tab3: '提现记录',
  withdraw_tab4: '添加收款账户',
  withdraw_title1: '账户信息',
  withdraw_title2: '提现到',
  withdraw_title3: '银行账户名',
  withdraw_title4: '银行账户',
  withdraw_title5: '收款人地址',
  withdraw_title6: '提现信息',
  withdraw_title7: '提现币种',
  withdraw_title8: '币种账户余额',
  withdraw_title9: '提现金额',
  withdraw_title10: '手续费承担方式',
  withdraw_title11: '手续费币种',
  withdraw_title12: '手续费币种余额',
  withdraw_title13: '手续费',
  withdraw_title14: '汇款用途',
  withdraw_title15: '一般商业用途',
  withdraw_title16: '交易附言',
  withdraw_title17: '定制付款人',
  withdraw_title18: '请选择付款名称',
  withdraw_title19: '付款信息',
  withdraw_title20: '付款币种',
  withdraw_title21: '付款金额',
  /*----------------withdraw pages end------------------------*/

  /*---------------- do-payment pages------------------------*/

  do_payment_title1: '账户信息',
  do_payment_title2: '请选择收款人',
  do_payment_title3: '交易材料',
  do_payment_title4: '请上传与该笔付款相关的发票或订单等相关证明文件',
  do_payment_title5: '交易信息',
  do_payment_title6: '提现流水号',
  do_payment_title7: '付款信息',
  do_payment_title8: '付款人',
  do_payment_title9: '收款账号',
  do_payment_title10: '付款账号',
  do_payment_title11: '收款人',
  do_payment_title12: '手续费金额',
  do_payment_title13: '收款行',
  do_payment_title14: '中间行名称',
  do_payment_title15: '中间行',
  do_payment_title16: '只可上传一个文件',
  do_payment_title17: '查询中，请稍后',
  do_payment_title18: '上传失败，请稍后再试',
  do_payment_title19: '提现申请成功',

  /*----------------do-payment pages end------------------------*/

  /*---------------- withdraw-record pages------------------------*/
  withdraw_record_title1: '序号',
  withdraw_record_title2: '币种',
  withdraw_record_title3: '账号',
  withdraw_record_title4: '时间',
  withdraw_record_title5: '交易结果',
  withdraw_record_title6: '待审核',
  withdraw_record_title7: '处理中',
  withdraw_record_title8: '已成功',
  withdraw_record_title9: '已失败',
  withdraw_record_title10: '下载回单',

  /*----------------withdraw-record pages end------------------------*/

  /*---------------- bind-account pages------------------------*/

  bind_account_title1: '提现账户',
  bind_account_title2: '新增同名提现账户',
  bind_account_title3: '新增收款账户',
  bind_account_title4: '账号名称',
  bind_account_title5: '账户号',
  bind_account_title6: '账户类型',
  bind_account_title7: '支付账户',
  bind_account_title8: '开户行',
  bind_account_title9: '分行',
  bind_account_title10: '区域',
  bind_account_title11: '昵称',
  bind_account_title12: '已通过',
  bind_account_title13: '已拒绝',
  bind_account_title14: '确定删除吗?',
  bind_account_title15: '新增',
  bind_account_title16: '银行账户信息',
  bind_account_title17: '收款',
  bind_account_title18: '请输入',
  bind_account_title19: '银行信息，以确保准确处理您的提现',
  bind_account_title20: '银行账号',
  bind_account_title21: '开户行地址',
  bind_account_title22: '收款方基本信息',
  bind_account_title23: '请输入收款方基本信息，以确保准确处理您的提现',
  bind_account_title24: '公司',
  bind_account_title25: '收款方名称',
  bind_account_title26: '收款方所在国家或地区',
  bind_account_title27: '详细地址',
  bind_account_title28: '账户昵称',
  bind_account_title29: '银行开户许可证',
  bind_account_title30: '合同',
  bind_account_title31: '返回账户列表',
  bind_account_title32: '请等待审核',
  bind_account_title33: '我们承担(OUR)',
  bind_account_title34: '共同承担(SHA)',
  bind_account_title35: '收款人承担(BEN)',
  /*----------------bind-account pages end------------------------*/

  /* 补齐漏掉的 */
  other_title1: '中国大陆',
  other_title2: '中国香港',
  other_title3: '其他',
  other_title4: '期限',
  other_title5: '系统设置',
};
